<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          RECEPCION / DESPACHO DE CARGA
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-col cols="2">
          <v-text-field
            class="mt-6"
            v-model="busAwbNum"
            label="Número de guía"
            clearable
            @keyup.enter="cargarLista(true)"
          ></v-text-field>
          <!-- <v-autocomplete
            label="Guia"
            class="mt-6"
            v-model="busCabeceraId"
            :items="guiasCoord"
            item-text="NOM_GUIA"
            item-value="CABECERA_ID"
            @change="cargarLista()"
          >
          </v-autocomplete> -->
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            label="Transportista"
            class="mt-6"
            clearable
            v-model="busTransp"
            :items="transportistas"
            item-text="TRANSP_NOMBRE"
            item-value="TRANSP_ID"
            @change="cargarLista(true)"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            label="Cliente final"
            class="mt-6"
            clearable
            v-model="busCfinal"
            :items="cfinales"
            item-text="CFINAL_NOMBRE"
            item-value="ENTE_ID"
            @change="cargarLista(true)"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <!-- <v-select
            class="mt-6 mb-0 pb-0"
            label="estado"
            v-model="searchEstado"
            :items="estados"
            item-text="descripcion"
            item-value="estado_id"
          ></v-select> -->
          <!-- <v-autocomplete
            label="Sub cliente"
            class="mt-6"
            clearable
            v-model="busScliente"
            :items="sclientes"
            item-text="SCLIENTE_NOMBRE"
            item-value="ENTE_ID"
            @change="cargarLista(true)"
          >
          </v-autocomplete> -->
        </v-col>
        <v-col cols="2">
          <v-text-field
            class="mt-6 mb-0 pb-0"
            label="buscar"
            v-model="searchCargaBod"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar>
        <v-btn small color="blue" dark @click="imprimirLote"
          ><v-icon left>mdi-printer</v-icon>Etiquetas</v-btn
        >

        <v-btn class="ml-4" small color="blue" dark @click="recibirCajasSel"
          ><v-icon left> mdi-package-variant-closed-plus</v-icon>Recibir
          cajas</v-btn
        >

        <v-btn class="ml-4" small color="blue" dark @click="abrirDialogRecibir"
          ><v-icon left>mdi-barcode-scan </v-icon>Escanear cajas</v-btn
        >

        <v-btn class="ml-4" small color="blue" dark @click="abrirDialogPod"
          ><v-icon left>mdi-truck</v-icon> crear POD</v-btn
        >
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="searchCargaBod"
        :loading="loadingTable"
        class="elevation-1"
        :item-class="colorFila"
        v-model="itemsSel"
        item-key="ID"
        show-select
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn icon small color="green" @click="abrirDialogCargaDim(item)">
            <v-icon>mdi-move-resize</v-icon>
          </v-btn>

          <v-btn icon small color="red" @click="descargarEtiqueta(item)">
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo carga dimensiones -->
    <v-dialog v-model="dialogCargaDim" max-width="700px" scrollable>
      <v-card>
        <v-toolbar flat color="blue" height="40" dark fixed>
          <v-card-title class="mx-0 mt-1 px-0">
            <v-icon class="mb-1" left>mdi-truck</v-icon>
            Datos cajas
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCargaBodega">
              <v-row>
                <v-col class="pt-2 pb-0" cols="12">
                  <v-text-field
                    tabindex="-1"
                    readonly
                    v-model="datosCargaDim.FINCA"
                    label="Finca"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.CLIENTE_FINAL"
                    label="Cliente"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    readonly
                    v-model="datosCargaDim.TIPO_CAJA"
                    label="Tipo de caja"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    tabindex="-1"
                    class="py-0"
                    v-model="datosCargaDim.NUM_GUIA_HIJA"
                    label="Guia Hija"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    id="piezas"
                    ref="txtPiezas"
                    tabindex="0"
                    :rules="requiredRule"
                    v-model="datosCargaDim.PIEZAS"
                    label="Piezas"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.ANCHO"
                    label="Ancho"
                    tabindex="1"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.ALTO"
                    label="Alto"
                    tabindex="2"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.LARGO"
                    label="Largo"
                    tabindex="3"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosCargaDim.PESO"
                    label="Peso"
                    tabindex="4"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-autocomplete
                    tabindex="-1"
                    label="Transportista"
                    v-model="datosCargaDim.TRANSPORTISTA_ID"
                    :items="transportistas"
                    item-text="TRANSP_NOMBRE"
                    item-value="TRANSP_ID"
                    @change="cargarLista(true)"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    tabindex="-1"
                    type="date"
                    v-model="datosCargaDim.FECHA_TRANSPORTE"
                    label="Fecha de transporte"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headersItems"
                    :items="listaItemsBod"
                    :items-per-page="itemsPerPage"
                    :search="searchCargaBodItem"
                    :loading="loadingTable"
                    class="elevation-1"
                    v-model="itemsBodSel"
                    item-key="ID"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarCargaDim">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn small @click="dialogCargaDim = !dialogCargaDim">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo recibir cajas -->
    <v-dialog v-model="dialogRecibirCajas" max-width="500px">
      <v-card>
        <v-toolbar flat color="blue" height="40" dark>
          <v-card-title class="mx-0 mt-1 px-0">
            <v-icon class="mb-1" left>mdi-package-variant-closed-plus</v-icon>
            Recibir cajas
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formRecibirCaja" onsubmit="return false">
            <v-row>
              <v-col class="pt-4 pb-0" cols="12">
                <v-text-field
                  autofocus
                  :rules="requiredRule"
                  v-model="datosRecaja.CODIGO"
                  label="Código de barra"
                  ref="inputBarcode"
                  @blur="$refs.inputBarcode.focus()"
                  @keydown.enter="recibirCajas()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small @click="dialogRecibirCajas = !dialogRecibirCajas">
                  <v-icon>mdi-cancel</v-icon> Salir
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo crear POD  -->
    <v-dialog v-model="dialogPod" max-width="700px">
      <v-card>
        <v-toolbar flat color="blue" height="40" dark>
          <v-card-title class="mx-0 mt-1 px-0">
            <v-icon class="mb-1" left>mdi-package-variant-closed-plus</v-icon>
            CREAR POD
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-1" icon small @click="dialogPod = !dialogPod"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="formCrearPod">
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                  label="Transportista"
                  class="mt-6"
                  clearable
                  :rules="requiredRule"
                  v-model="datosPodHead.TRANSPORTISTA_ID"
                  :items="transportistas"
                  item-text="TRANSP_NOMBRE"
                  item-value="TRANSP_ID"
                  @change="cargarLista(true)"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  label="Cliente final"
                  class="mt-6"
                  clearable
                  :rules="podVariosClientes == false ? requiredRule : []"
                  v-model="datosPodHead.CLIENTE_FINAL_ID"
                  :items="cfinales"
                  item-text="CFINAL_NOMBRE"
                  item-value="ENTE_ID"
                  @change="cargarLista(true)"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  :rules="requiredRule"
                  label="MAWB"
                  class="mt-5"
                  clearable
                  v-model="datosPodHead.AWB_NUM"
                >
                </v-text-field>
              </v-col>
              <v-col
                ><span v-if="podVariosClientes == true"
                  >Items seleccionados: {{ this.itemsParaPod }}</span
                ></v-col
              >
            </v-row>
            <v-row class="pt-4 mb-1 mx-1">
              <v-btn small color="primary" @click="ingresarPodHead()"
                ><v-icon>mdi-content-save</v-icon>Crear POD</v-btn
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.fila_verde {
  background-color: #b2f78a;
}
.fila_amarilla {
  background-color: #f7eda5;
}
.fila_roja {
  background-color: "red";
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
var intervalbarCode = "";
export default {
  name: "CargaBodega",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "MAWB", value: "AWB" },
      { text: "HAWB", value: "NUM_GUIA_FINCA" },
      { text: "FINCA", value: "FINCA" },
      { text: "CLIENTE FINAL", value: "CLIENTE_FINAL" },
      { text: "TIPO DE CAJA", value: "CAJA_COD" },
      { text: "PIEZAS", value: "PIEZAS" },
      { text: "PIEZAS RECIBIDAS", value: "PIEZAS_RECIBIDAS" },
      { text: "TRANSPORTISTA", value: "TRANSP_NOMBRE" },
      { text: "DIA", value: "DIA_TRANSP" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email válido",
    ],
    lista: [],

    options: {},
    podVariosClientes: false,
    itemsParaPod: 0,
    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 40,

    dialogTransportista: false,
    datosTransportista: {
      TRANSP_ID: "",
      TRANSP_NOMBRE: "",
      ACTIVO: 1,
    },

    dialogCargaDim: false,
    datosCargaDim: { FINCA: "", CLIENTE: "", CLIENTE_FINAL: "" },
    fechaArriboEstimada: "",

    nuevoTransp: false,
    busCabeceraId: "",
    busTransp: "",
    busCfinal: "",
    busScliente: "",
    busAwbNum: "",
    search: "",

    guiasCoord: [],
    itemsSel: [],
    searchCargaBod: "",
    searchEstado: "",
    estados: [
      { estado_id: "RECIBIDO", descripcion: "RECIBIDA" },
      { estado_id: "CREADO", descripcion: "CREADA" },
      { estado_id: "ENVIADO", descripcion: "ENVIADO" },
    ],

    transportistas: [],
    cfinales: [],
    sclientes: [],

    dialogRecibirCajas: false,
    datosRecaja: { CODIGO: null },

    headersItems: [
      { text: "Codigo", value: "ID" },
      { text: "TIPO DE CAJA", value: "TIPO_CAJA" },
      { text: "ESTADO", value: "ESTADO" },
    ],
    listaItemsBod: [],
    searchCargaBodItem: "",
    itemsBodSel: [],

    dialogPod: false,

    datosPodHead: {},
    itemsPod: [],
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "impresoraId"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    colorFila(item) {
      if (item.PIEZAS == "" || item.PIEZAS_RECIBIDAS == 0) {
        return "white";
      }
      if (item.PIEZAS_RECIBIDAS > 0 && item.PIEZAS_RECIBIDAS < item.PIEZAS) {
        return "fila_amarilla";
      }

      if (item.PIEZAS_RECIBIDAS == item.PIEZAS) {
        return "fila_verde";
      }

      if (item.CAJAS_BODEGA > item.CAJAS_BODEGA) {
        return "fila_roja";
      }

      return "white";
    },
    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/datos-carga-recibida");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res);
          this.guiasCoord = res.data.guiasCoord;
          this.transportistas = res.data.transportistas;
          this.cfinales = res.data.cfinales;
          this.sclientes = res.data.sclientes;
          //this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLista(resetPagination = false) {
      if (resetPagination == true) {
        this.currentPage = 1;
      }
      this.setLoadingTable(true);
      this.setUrl("api/lista-carga-bodega");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          busTransp: this.busTransp,
          busCfinal: this.busCfinal,
          busAwbNum: this.busAwbNum,
          CABECERA_ID: this.busCabeceraId,
          busScliente: this.busScliente,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    editarTransportista(transpId) {
      this.nuevoTransp = false;
      this.abrirDialogTransportista(transpId);
    },
    abrirDialogTransportista(transpId) {
      this.setUrl("api/transportista");
      this.requestApi({
        method: "GET",
        data: {
          TRANSP_ID: transpId,
        },
      })
        .then((res) => {
          this.datosTransportista = res.data[0];
          this.dialogTransportista = true;
        })
        .then(() => {});
    },

    eliminarTransportista(transportistaId) {
      Vue.swal({
        html: "Está seguro de eliminar el transportista ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.isConfirmed) {
            this.setUrl("api/borrar-transportista");
            this.requestApi({
              method: "POST",
              data: {
                TRANSP_ID: transportistaId,
              },
            }).then((res) => {
              //console.log(res);
              this.cargarLista();
              this.alertNotification({ param: { html: res.data.msg } });
            });
          }
        }
      });
    },
    guardarFechaTransporte() {
      if (this.fechaArriboEstimada == "") {
        return false;
      }

      this.setUrl("api/guardar-fecha-transporte");
      this.requestApi({
        method: "POST",
        data: {
          FECHA_TRANSPORTE: this.fechaArriboEstimada,
          CABECERA_ID: this.busCabeceraId,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogTransportista = false;
        })
        .then(() => {});
    },
    abrirDialogCargaDim(item) {
      this.cargarDatosCargaDim(item);
      this.dialogCargaDim = true;
    },
    cargarDatosCargaDim(item) {
      this.setUrl("api/datos-carga-bodega");
      this.requestApi({
        method: "GET",
        data: {
          ID: item.ID,
        },
      }).then((res) => {
        this.datosCargaDim = res.data.datos_carga;
        this.listaItemsBod = res.data.items_bodega;
        //this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    modificarCargaDim() {
      this.setUrl("api/modificar-carga-bodega");
      this.dialogCargaDim = true;
      this.requestApi({
        method: "POST",
        data: {
          datos_carga: this.datosCargaDim,
        },
      }).then((res) => {
        this.cargarDatosCargaDim(this.datosCargaDim);
        this.cargarLista();
        this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    guardarCargaDim() {
      this.modificarCargaDim();
    },
    descargarEtiqueta(item) {
      this.setUrl("api/etiqueta-carga-bodega");

      this.requestApi({
        method: "POST",
        data: {
          ID: item.ID,
          codigo_proceso: this.impresoraId,
        },
      }).then((res) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + res.data.datos;
        a.download = res.data.nombreArchivo;

        a.click();
        this.alertNotification({ param: { html: res.data.msg } });
      });
    },
    imprimirLote() {
      this.setUrl("api/etiqueta-carga-bodega");
      let listaImp = [];
      //console.log(this.itemsSel);
      for (let i = 0; i < this.itemsSel.length; i++) {
        listaImp.push(this.itemsSel[i].ID);
      }
      if (listaImp.length == 0) {
        alert("Seleccione al menos una etiqueta");
        return false;
      }
      this.requestApi({
        method: "POST",
        data: {
          IDS: listaImp,
          codigo_proceso: this.impresoraId,
        },
      }).then((res) => {
        let a = document.createElement("a");
        a.href = "data:application/pdf;base64," + res.data.datos;
        a.download = res.data.nombreArchivo;
        a.click();
        this.itemsSel = [];

        this.alertNotification({ param: { html: res.data.msg } });
      });
    },

    recibirCajasSel() {
      this.setUrl("api/recibir-carga-bodega-sel");
      let listaImp = [];
      console.log(this.itemsSel);
      for (let i = 0; i < this.itemsSel.length; i++) {
        listaImp.push(this.itemsSel[i].ID);
      }
      if (listaImp.length == 0) {
        alert("Seleccione al menos una etiqueta");
        return false;
      }
      this.requestApi({
        method: "POST",
        data: {
          IDS: listaImp,
        },
      }).then((res) => {
        this.itemsSel = [];
        this.alertNotification({ param: { html: res.data.msg } });
        this.cargarLista();
      });
    },

    handlePageChange() {
      this.cargarLista();
    },
    onkeyup(e) {
      //alert(e.keyCode);

      //C
      if (e.altKey && e.keyCode == 67) {
        alert("CANCELAR");
        this.dialogCargaDim = false;
      }
      //G
      /*if (e.altKey && e.keyCode == 71) {
        alert("GUARDAR");
      }
      //B
      if (e.altKey && e.keyCode == 66) {
        alert("BORRAR");
      }
      //M
      if (e.altKey && e.keyCode == 77) {
        alert("MODIFICAR");
      }*/

      if (e.altKey && e.keyCode == 82) {
        this.abrirDialogRecibir();
      }

      if (e.altKey && e.keyCode == 80) {
        this.abrirDialogPod();
      }
      //console.log(e.keyCode);
    },
    abrirDialogRecibir() {
      this.dialogRecibirCajas = true;
      this.$nextTick(() => this.$refs.inputBarcode.focus());
    },
    recibirCajas() {
      //clearInterval(intervalbarCode);
      if (!this.$refs.formRecibirCaja.validate()) {
        return false;
      }
      //intervalbarCode = setTimeout(() => {
      this.setUrl("api/recibir-carga-bodega");
      this.requestApi({
        method: "POST",
        data: {
          ID: this.datosRecaja.CODIGO,
        },
      })
        .then((res) => {
          //console.log(res);
          if (res.data.error == true) {
            this.alertNotification({
              param: { html: res.data.msg, icon: "error", title: "Error" },
            });
          } else {
            this.alertNotification({ param: { html: res.data.msg } });
            this.cargarLista();
          }
        })
        .catch(() => {
          this.setLoadingTable(false);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
      this.datosRecaja.CODIGO = null;
      //}, 200);
    },
    abrirDialogPod() {
      this.dialogPod = true;

      let selItemsPod = [];

      for (let i = 0; i < this.itemsSel.length; i++) {
        selItemsPod.push(this.itemsSel[i].ID);
      }

      if (selItemsPod.length > 0) {
        this.podVariosClientes = true;
        this.itemsParaPod = selItemsPod.length;
      }
      this.datosPodHead.CLIENTE_FINAL_ID = this.busCfinal;
      this.datosPodHead.TRANSPORTISTA_ID = this.busTransp;
      this.datosPodHead.AWB_NUM = this.busAwbNum;
    },

    ingresarPodHead() {
      let selItemsPod = [];

      for (let i = 0; i < this.itemsSel.length; i++) {
        selItemsPod.push(this.itemsSel[i].ID);
      }

      if (selItemsPod.length > 0) {
        this.podVariosClientes = true;
        this.itemsParaPod = selItemsPod.length;
      }

      if (!this.$refs.formCrearPod.validate()) {
        return false;
      }

      this.setUrl("api/crear-pod");
      this.requestApi({
        method: "POST",
        data: {
          datosPod: this.datosPodHead,
          itemsPod: selItemsPod,
          podVariosClientes: this.podVariosClientes,
        },
      })
        .then((res) => {
          //console.log(res);
          this.alertNotification({ param: { html: res.data.msg } });

          this.itemsSel = [];
          router.push({
            name: "FormPod",
            params: { podId: res.data.podHead.POD_ID },
          });
        })
        .catch(() => {})
        .then(() => {});
    },
    cargarPod() {
      this.setUrl("api/cargar-datos-pod");
      this.requestApi({
        method: "GET",
        data: {
          POD_ID: "",
        },
      })
        .then((res) => {
          //console.log(res);
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarPod();
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
  },

  mounted() {
    document.title = "Transacciones de bodega";

    this.setTitleToolbar("Transacciones de Bodega");
    this.cargarDatos();
    this.cargarLista();
  },

  created() {
    document.onkeyup = this.onkeyup;
  },
};
</script>
